import React from "react"

import { withStores } from "./withStores"
import { HeroBanner } from "../Sections/HeroBanner/HeroBanner"
import { StoresItem } from "./Item/StoresItem"
import { StoresLocations } from "./Locations/StoresLocations"

interface Props {
  content: any
  hero: any
  location: any
  products: Array<any>
}

export const Stores = withStores(({ content, hero, location, products }: Props) => (
  <>
    {hero && <HeroBanner section={hero} condensed={`true`} />}
    <StoresItem content={content} />
    <StoresLocations location={location} products={products} />
  </>
))
