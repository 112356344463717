import { useState, useEffect } from "react"
import { graphql, navigate, useStaticQuery } from "gatsby"

import { useApp } from "./useApp"
import { useRoutes } from "./useRoutes"
import { useShopify } from "./useShopify"

const query = graphql`
  query STORES_QUERY {
    stores: allSanityStore {
      edges {
        node {
          id: _id
          _rawAddress(resolveReferences: { maxDepth: 10 })
          _rawLocation(resolveReferences: { maxDepth: 10 })
          _rawSlug(resolveReferences: { maxDepth: 10 })
          _rawProductRanges(resolveReferences: { maxDepth: 10 })
          email
          country
          state
          title
          productRanges {
            title
          }
        }
      }
    }
  }
`

export const useStores = location => {
  const {
    config: {
      settings: { params },
    },
  } = useApp()
  const { edgeNormaliser } = useShopify()
  const { getUrlParameter, setUrlParameter } = useRoutes()
  const data = useStaticQuery(query)

  const stores = edgeNormaliser(data?.stores).map(store => ({
    ...store,
    location: { ...store._rawLocation, type: (store._rawLocation || {})._type },
    slug: store._rawSlug.current,
    address: store._rawAddress,
    addressText: store._rawAddress?.[0]?.children?.[0]?.text,
    productRanges: store.productRanges.map(range => range.title),
  }))

  const [filteredStores, setFilteredStores] = useState(stores)
  const [search, setSearch] = useState(getUrlParameter(params.stores)?.toLowerCase() || ``)
  const [filters, setFilters] = useState({
    country: getUrlParameter(params.country),
    range: getUrlParameter(params.range),
    state: getUrlParameter(params.state),
  })

  const handleFilters = (type, value) => {
    const newFilters = {
      ...filters,
    }

    if (type === `country`) {
      newFilters[`state`] = null
    }

    newFilters[type] = value
    setFilters(null)
    setSearch(null)
    setFilters(newFilters)
  }

  const handleResetFilters = filters => {
    setFilters(null)
    setSearch(null)
    setFilters(filters)
  }

  const handleHighlight = store =>
    search &&
    (store.title?.toLowerCase()?.includes(search) ||
      store?.addressText?.toLowerCase()?.includes(search) ||
      store.country?.toLowerCase()?.includes(search))

  useEffect(() => {
    const { country, range, state } = filters
    const filteredStores = stores
      .filter(store => store.country === country || !country)
      .filter(store => store.state === state || !state)
      .filter(store => store.productRanges?.includes(range) || !range)

    let url = location.pathname
    if (search) {
      url = setUrlParameter(params.stores, search, url)
    }
    if (country) {
      url = setUrlParameter(params.country, country, url)
    }
    if (state) {
      url = setUrlParameter(params.state, state, url)
    }
    if (range) {
      url = setUrlParameter(params.range, range, url)
    }

    setFilteredStores(filteredStores)
    navigate(url, { replace: true })
  }, [filters])

  const availableCountries = stores
    .map(store => store.country)
    .filter((item, index, array) => array.indexOf(item) === index)
    .sort()

  const availableStates = stores
    .filter(store => store.state && store.country === filters[`country`] && filters[`country`])
    .map(store => store.state)
    .filter((item, index, array) => array?.indexOf(item) === index)
    .sort()

  const filtered = stores.length !== filteredStores.length

  return {
    availableCountries,
    availableStates,
    filtered,
    filteredStores,
    filters,
    handleFilters,
    handleHighlight,
    handleResetFilters,
  }
}
