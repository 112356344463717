import React from "react"
import { graphql } from "gatsby"

import { Stores as Page } from "../components/Stores/Stores"

export const query = graphql`
  query {
    page: sanityStoresPage {
      _rawContent(resolveReferences: { maxDepth: 10 })
      heroBanner: _rawHeroBanner(resolveReferences: { maxDepth: 10 })
      _rawLinks(resolveReferences: { maxDepth: 10 })
      _rawConsultationButtonLink(resolveReferences: { maxDepth: 10 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content {
        ... on SanityImageType {
          _key
          _type
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    products: allSanityProductRange {
      edges {
        node {
          title
        }
      }
    }
  }
`

export default ({ data, ...props }) => <Page {...props} {...data} />
