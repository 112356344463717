import React from "react"
import { Link } from "gatsby"

import { withStoresItem } from "./withStoresItem"
import { RichText } from "../../RichText/RichText"
import { Booking } from "../../Booking/Booking"
import { Image } from "../../Image/Image"
import {
  Column,
  ContentItem,
  ContentLinks,
  ImageContainer,
  ImageRatio,
  ImageWrapper,
  Row,
  StyledContainer,
  StyledPrimaryButton,
  StyledLink,
} from "../StoresStyles"

interface Props {
  booking: any
  content: any
  images: Array<any>
  items: Array<any>
  links: Array<any>
  setBooking: any
}

export const StoresItem = withStoresItem(({ booking, content, images, items, links, setBooking }: Props) => (
  <>
    <StyledContainer width={`lg`}>
      <Row>
        <Column content>
          {items.map((content, index) => (
            <ContentItem key={index}>
              <RichText content={content} />
            </ContentItem>
          ))}

          <ContentLinks>
            {content?.consultationButton?.title && content?.consultationButton?.url ? (
              content.consultationButton.type === `internal` ? (
                <StyledPrimaryButton as={Link} full={`true`} to={content.consultationButton.url}>
                  {content.consultationButton.title}
                </StyledPrimaryButton>
              ) : content.consultationButton.url.includes(`calendly`) ? (
                <StyledPrimaryButton full={`true`} onClick={() => setBooking(content.consultationButton.url)}>
                  {content.consultationButton.title}
                </StyledPrimaryButton>
              ) : (
                <StyledPrimaryButton as={`a`} full={`true`} href={content.consultationButton.url} target={`_blank`}>
                  {content.consultationButton.title}
                </StyledPrimaryButton>
              )
            ) : null}

            {links?.length > 0 ? (
              <Row>
                {links?.map(link =>
                  link?.type === `internal` ? (
                    <StyledLink key={link.title} to={link?.url}>
                      {link.title}
                    </StyledLink>
                  ) : link?.url.includes(`calendly`) ? (
                    <StyledLink key={link.title} onClick={() => setBooking(content.consultationButton.link.url)}>
                      {content.consultationButton.text}
                    </StyledLink>
                  ) : (
                    <StyledLink key={link.title} as={`a`} target={!link?.url.includes(`mailto:`) ? `_blank` : null} href={link?.url}>
                      {link.title}
                    </StyledLink>
                  )
                )}
              </Row>
            ) : null}
          </ContentLinks>
        </Column>

        <Column>
          {images.map(content => (
            <ImageContainer key={content.key}>
              <ImageRatio />
              <ImageWrapper>
                <Image cover src={content.fluid.src} />
              </ImageWrapper>
            </ImageContainer>
          ))}
        </Column>
      </Row>
    </StyledContainer>

    <Booking active={booking ? true : false} booking={booking} setActive={setBooking} />
  </>
))
