import React, { useState } from "react"

export const withStoresItem = Component => ({ name = `StoresItem`, content: page }) => {
  const [booking, setBooking] = useState(false)

  const images = page?.content?.filter(content => content.type === `image` && content?.fluid?.src)
  const items = page?.content?.filter(content => content.type !== `image`)
  const links = page?.links?.filter(link => link?.url && link?.title)

  Component.displayName = name
  return <Component booking={booking} content={page} images={images} items={items} links={links} setBooking={setBooking} />
}
