import React from "react"
import { Link } from "gatsby"

import { withStoresLocations } from "./withStoresLocations"
import { RichText } from "../../RichText/RichText"
import { Checkbox } from "../../Styled/Checkbox/Checkbox"
import { Dropdown } from "../../Styled/Dropdown/Dropdown"
import { H5 } from "../../Styled/Text"
import { PrimaryButton } from "../../Styled/Button"
import {
  Checkboxes,
  CheckboxWrapper,
  Details,
  Filter,
  DropdownWrapper,
  IconNext,
  Empty,
  Links,
  LocationContent,
  LocationsRow,
  LocationsContainer,
  LocationsColumn,
  StyledLabel,
  StyledP,
  StyledTextButton,
  Stores,
  Store,
  Wrapper,
} from "../StoresStyles"

interface Props {
  countries: Array<any>
  filters: any
  filtered: any
  handleFilters: any
  handleResetFilters: any
  handleHighlight: any
  locales: any
  ranges: Array<any>
  routes: any
  states: any
  stores: Array<any>
}

export const StoresLocations = withStoresLocations(
  ({ countries, filters, filtered, handleFilters, handleResetFilters, handleHighlight, locales, ranges, routes, states, stores }: Props) => (
    <LocationsContainer width={`lg`}>
      <Wrapper>
        <LocationsRow spacing>
          <LocationsColumn>
            <LocationsRow bottom>
              <LocationsColumn>
                <Filter>
                  <StyledP as={`label`}>{locales.country}</StyledP>
                  <DropdownWrapper>
                    <Dropdown
                      label={locales.selectCountry}
                      options={[
                        {
                          label: locales.allCountries,
                          value: null,
                        },
                        ...countries,
                      ]}
                      value={filters.country}
                      onChange={value => handleFilters(`country`, value)}
                    />
                  </DropdownWrapper>
                </Filter>

                <Filter hiddenLg={`true`}>
                  <StyledP as={`label`}>{locales.state}</StyledP>
                  <DropdownWrapper>
                    <Dropdown
                      disabled={!filters.country}
                      label={locales.selectState}
                      options={states}
                      value={filters.country ? filters.state : null}
                      onChange={value => handleFilters(`state`, value)}
                    />
                  </DropdownWrapper>
                </Filter>
              </LocationsColumn>

              <LocationsColumn>
                <PrimaryButton full={`true`}>{locales.find}</PrimaryButton>
              </LocationsColumn>
            </LocationsRow>
          </LocationsColumn>
        </LocationsRow>

        <LocationsRow spacing>
          <LocationsColumn>
            <H5>{`${locales.index}, ${filters.country ? filters.country : locales.allCountries}`}</H5>
          </LocationsColumn>

          <LocationsColumn>
            <Checkboxes>
              <CheckboxWrapper>
                <Checkbox checked={!filters.range} centered externalState large name={`all`} onChange={() => handleFilters(`range`, null)}>
                  {locales.any}
                </Checkbox>
              </CheckboxWrapper>

              {ranges.map(productRange => (
                <CheckboxWrapper key={productRange}>
                  <Checkbox
                    checked={filters.range === productRange}
                    centered
                    externalState
                    large
                    name={productRange}
                    onChange={() => handleFilters(`range`, filters.range !== productRange ? productRange : null)}
                  >
                    {productRange}
                  </Checkbox>
                </CheckboxWrapper>
              ))}
            </Checkboxes>
          </LocationsColumn>
        </LocationsRow>

        <Stores as={stores.length ? `ul` : null} spacing>
          {stores.length ? (
            stores.map(store => (
              <Store key={store.slug}>
                <LocationContent highlight={handleHighlight(store)}>
                  <Details>
                    <StyledLabel>{store.title}</StyledLabel>
                    <StyledP as={`div`}>
                      <RichText content={store.address} />
                    </StyledP>
                  </Details>

                  <Links>
                    {store.email ? (
                      <StyledTextButton as={`a`} href={`mailto:${store.email}`}>
                        {locales.emailButton}
                      </StyledTextButton>
                    ) : null}
                    <StyledTextButton as={Link} to={`${routes.STORE}/${store.slug}`}>
                      <span>{locales.viewButton}</span> <IconNext />
                    </StyledTextButton>
                  </Links>
                </LocationContent>
              </Store>
            ))
          ) : (
            <Empty>{locales.empty}</Empty>
          )}
        </Stores>

        <LocationsRow end>
          <LocationsColumn end>
            <PrimaryButton disabled={!filtered} full onClick={() => handleResetFilters({ country: null, ranges })}>
              {locales.reset}
            </PrimaryButton>
          </LocationsColumn>
        </LocationsRow>
      </Wrapper>
    </LocationsContainer>
  )
)
